function checkIfIsIpad() {
	return navigator.userAgent.match(/iPad/i) !== null;
}

const appendVideo = (videoWrapper) => {
	const videoUrl = videoWrapper.dataset.video;
	const videoId = videoWrapper.dataset.id || '';
	const videoPoster = videoWrapper.dataset.poster || '';
	const preload = videoWrapper.dataset.preload || 'metadata';
	const isLoop = videoWrapper.dataset.isloop
		? videoWrapper.dataset.isloop
		: 'loop'; // set loop if none was passed
	const fallbackImage = videoWrapper.dataset.fallback || '';
	if (checkIfIsIpad()) {
		const img = document.createElement('img');
		img.src = fallbackImage || videoPoster;
		img.alt = 'Video';
		img.id = videoId;
		videoWrapper.appendChild(img);
		return;
	}

	const video = document.createElement('video');
	video.muted = true;
	video.playsInline = true;
	video.autoplay = false;
	video.loop = isLoop === 'loop';
	video.preload = preload;
	video.poster = videoPoster;
	video.controls = false;
	video.id = videoId;
	video.classList.add('lazy');

	const videoUrls = videoUrl.split(',');
	videoUrls.forEach((url) => {
		const source = document.createElement('source');
		source.src = url.trim();
		const videoType = url.trim().split('.').pop();
		source.type = `video/${videoType}`;
		video.appendChild(source);
		video.style.opacity = '0';
	});

	videoWrapper.appendChild(video);
	const playPromise = video.play();
	if (playPromise !== undefined) {
		playPromise
			.then(() => {
				// Automatic playback started!
				video.style.opacity = '1';
				setTimeout(() => {
					videoWrapper.style.backgroundImage = 'none';
				}, 500);
			})
			.catch((error) => {
				video.style.opacity = '1';
				console.log(error);
			});
	}
};

document.addEventListener('DOMContentLoaded', () => {
	const videos = document.querySelectorAll('.video');
	videos.forEach((videoWrapper) => {
		appendVideo(videoWrapper);
	});
});
