// footer accordion
(function footerAccordion() {
	const footerAccordionHeader =
		document.querySelectorAll('.footer-menu-title');
	let i;
	for (i = 0; i < footerAccordionHeader.length; i++) {
		footerAccordionHeader[i].addEventListener('click', (event) => {
			event.preventDefault();
			event.target.classList.toggle('active');
			const panel = event.target.nextElementSibling;
			if (panel.style.maxHeight) {
				panel.style.maxHeight = null;
			} else {
				panel.style.maxHeight = `${panel.scrollHeight}px`;
			}
		});
	}
})();

// Add event listener to track clicks on main navigation
(function trackMainNavigationClicks() {
	document
		.querySelectorAll('.menu-main-menu-container a')
		.forEach((anchor) => {
			anchor.addEventListener('click', () => {
				window.dataLayer.push({
					event: 'Main Navigation Click',
					item: anchor.textContent.trim(),
				});
			});
		});
})();

document.querySelectorAll(".menu-main-menu-container a").forEach(function (anchor) {
	anchor.addEventListener('click', function (e) {
		window.dataLayer.push({
			'event': 'Main Navigation Click',
			'item': anchor.textContent.trim()
		});
	});
});
