import { Dropdown, Collapse } from 'bootstrap'; // eslint-disable-line no-unused-vars
import AOS from 'simple-aos';
import Swiper from 'swiper';
import lottie from 'lottie-web';
import videojs from 'video.js';
import LazyLoad from 'vanilla-lazyload';
import { getBootstrapBreakpoints, autoSlide } from '../global/functions';
import '../components/video';

const initLazyLoad = new LazyLoad();
initLazyLoad.update();

AOS.init({
	disable: window.matchMedia('(prefers-reduced-motion: reduce)'), // reduce motion when user has set prefers-reduced-motion for accessibility
	easing: 'ease-in-out',
	duration: 800,
	offset: 80,
	anchorPlacement: 'bottom-bottom',
	once: true,
});

const breakpoints = getBootstrapBreakpoints();

const sliderNav = document.querySelector('.slider-nav');
const slideNavLinks = sliderNav.querySelectorAll('a');
const slideNavLinkActive = sliderNav.querySelector('li.active-slide');
const logoGroups = document.querySelectorAll('.logo-group');
let currentLogosGroup = 0;
let nextLogosGroup = 0;

// Set the slider nav active class and width

/**
 * Calculates the width and position of the navigation element based on the provided link.
 *
 * @param {HTMLElement} link - The link element used as a reference for calculating the navigation width and position.
 * @param {HTMLElement} nav - The navigation element to be styled.
 * @param {string} [prefix="nav"] - The prefix used for CSS custom properties.
 */

function calculateNavWidth(link, nav, prefix = 'nav') {
	nav.style.setProperty(`--${prefix}Transform`, `${link.offsetLeft}px`);
	nav.style.setProperty(`--${prefix}Width`, `${link.offsetWidth}px`);
}

// This function will change the active logo group
// and animate the logos in and out

const changeGroup = () => {
	const outgoingGroup = logoGroups[currentLogosGroup];
	currentLogosGroup = nextLogosGroup;
	const incomingGroup = logoGroups[nextLogosGroup];

	outgoingGroup.classList.add('outgoing');

	const outgoingLogos = outgoingGroup.querySelectorAll('.logo-wrap');
	outgoingLogos.forEach((logo, index) => {
		setTimeout(() => {
			const currentLogo = logo;
			currentLogo.style.opacity = '0';
			currentLogo.style.transform = 'translateY(-20px)';
		}, index * 50);
	});

	setTimeout(() => {
		outgoingGroup.classList.remove('active', 'outgoing');
	}, 500);

	incomingGroup.classList.add('active');
	const incomingLogos = incomingGroup.querySelectorAll('.logo-wrap');
	incomingLogos.forEach((logo, index) => {
		setTimeout(() => {
			const currentLogo = logo;
			currentLogo.style.opacity = '1';
			currentLogo.style.transform = 'translateY(0)';
		}, index * 100);
	});
};
// Set the slider nav active class and width
calculateNavWidth(slideNavLinkActive, sliderNav, 'nav');

slideNavLinks.forEach((slideNavLink) => {
	slideNavLink.addEventListener('click', (e) => {
		e.preventDefault();
		sliderNav
			.querySelector('li.active-slide')
			.classList.remove('active-slide');
		slideNavLink.parentElement.classList.add('active-slide');
		calculateNavWidth(slideNavLink, sliderNav, 'nav');
		nextLogosGroup = Array.prototype.indexOf.call(
			slideNavLinks,
			slideNavLink,
		);
		changeGroup();
	});
});

// Recalculate the slider nav on window resize
window.addEventListener('resize', () => {
	const slideNavLinkActiveSlide = sliderNav.querySelector('li.active-slide');
	calculateNavWidth(slideNavLinkActiveSlide, sliderNav, 'nav');
});

// Initial setup to show the first group immediately
logoGroups[0].classList.add('active');
logoGroups[0].querySelectorAll('.logo-wrap').forEach((logo) => {
	logo.style.opacity = '1';
	logo.style.transform = 'translateY(0)';
});

// Auto slide the slider nav
autoSlide(slideNavLinks, 2000);

/**
 *  * * Video Player
 */

const shortIntroVideo = document.querySelector('.short-intro-video');
const videoButton = document.querySelector('.video-button');
const player = videojs('full-intro-video', {
	playsinline: true,
});

videoButton.addEventListener('click', (e) => {
	e.preventDefault();
	shortIntroVideo.style.opacity = '0';
	// eslint-disable-next-line no-underscore-dangle
	player.el_.classList.remove('d-none');
	videoButton.classList.add('d-none');
	player.play();
});

/*
 * * Change the src of the accordion media
 * * according to the data-media attribute
 * * on the selected accordion
 */

const accordion = document.querySelector('.accordion');
const accordionMedia = document.querySelector('.accordion-media');
const accordionButton = accordion.querySelector('.accordion-button');
const accordionContent = accordion.querySelectorAll('.accordion-content');
const animations = {};
const animationUrls = [];
let animationsPreloaded = false;
let initAnimation;

accordionContent.forEach((e) => {
	e.getAttribute('data-media');
	animationUrls.push(e.getAttribute('data-media'));
});

// preload all the animations when scrolling down to section

document.addEventListener('scroll', () => {
	const videoSection = document.querySelector('.video-section');
	if (window.isInViewport(videoSection, 800) && !animationsPreloaded) {
		animationsPreloaded = true;
		animationUrls.forEach((url, index) => {
			fetch(url)
				.then((response) => response.json())
				.then((data) => {
					animations[`animation${index + 1}`] = data;
				});
		});
	}
});

// Initialize the animation on page load
initAnimation = lottie.loadAnimation({
	container: accordionMedia,
	renderer: 'svg',
	loop: false,
	autoplay: true,
	path: accordionMedia.getAttribute('data-animation'), // Default animation
});

accordion.addEventListener('show.bs.collapse', (e) => {
	// Destroy the previous animation
	initAnimation.destroy();

	// Update the animation path dynamically based on active accordion
	let nextAnimationIndex = e.target.getAttribute('data-acc');
	nextAnimationIndex = parseInt(nextAnimationIndex, 10) + 1;

	initAnimation = lottie.loadAnimation({
		container: accordionMedia,
		renderer: 'svg',
		loop: false,
		autoplay: true,
		animationData: animations[`animation${nextAnimationIndex}`],
		rendererSettings: {
			progressiveLoad: false, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
		},
	});
	accordionMedia.classList.remove('d-none');

	if (breakpoints.xs || breakpoints.sm || breakpoints.md) {
		e.target.appendChild(accordionMedia);
		accordionMedia.classList.add('d-block');
	}
});

// Remove the collapsed class and show class to close the accordion on mobile
if (breakpoints.xs || breakpoints.sm || breakpoints.md) {
	accordionButton.classList.add('collapsed');
	accordionContent.forEach((e) => {
		e.classList.remove('show');
	});
}

// on accordion change event add font weight medium to the active tab
const accordionButtons = document.querySelectorAll('.accordion-button');

accordionButtons.forEach((button) => {
	button.addEventListener('click', () => {
		const currentlyActive = document.querySelector(
			'.accordion-button.font-weight-medium',
		);
		if (currentlyActive && currentlyActive !== button) {
			currentlyActive.classList.remove('font-weight-medium');
			currentlyActive.classList.add('font-weight-light');
		}
		button.classList.toggle('font-weight-medium');
		button.classList.toggle('font-weight-light');
	});
});

/*
 * * logos-media-swiper
 */
/* eslint-disable no-new */
const logosMedia = document.querySelector('.logos-media-swiper');
const numberOfSlides = logosMedia.querySelectorAll('.swiper-slide').length;
new Swiper('.logos-media-swiper', {
	slidesPerView: 2,
	spaceBetween: 0,
	slidesPerGroup: 1,
	loop: true,
	autoplay: {
		delay: 2000,
	},
	breakpoints: {
		436: {
			slidesPerView: 3,
		},
		768: {
			slidesPerView: 5,
		},
		1024: {
			slidesPerView: numberOfSlides || 'auto',
			loop: false,
		},
	},
});
/* eslint-enable no-new */

/*
 * * Counter Animation
 */
let flag = true;
const runCounter = () => {
	document.querySelectorAll('.number').forEach((el) => {
		const element = el;
		const start = 0;
		const end = parseInt(element.getAttribute('data-stop'), 10);
		const duration = 4000;
		let startTime = null;
		const originalText = element.textContent;
		const prefix = originalText.match(/^[^\d]+/) || '';
		const suffix = originalText.match(/[^\d]+$/) || '';

		function animateCounter(timestamp) {
			if (!startTime) startTime = timestamp;
			const progress = timestamp - startTime;
			const current = Math.min(
				start + (progress / duration) * (end - start),
				end,
			);
			element.textContent = prefix + Math.round(current) + suffix;

			if (progress < duration) {
				requestAnimationFrame(animateCounter);
			} else {
				element.textContent = prefix + end + suffix;
			}
		}

		requestAnimationFrame(animateCounter);
	});
	flag = false;
};

document.addEventListener('scroll', () => {
	const stats = document.querySelector('.stats');
	if (window.isInViewport(stats, 800)) {
		if (flag) {
			runCounter();
		}
		flag = false;
	}
});

/*
 * * Success stories swiper
 */

const storiesNav = document.querySelector('.stories-nav');
const storiesNavLinks = storiesNav.querySelectorAll('img');
const StoriesNavLinkActive = storiesNav.querySelector('li.active-slide');

const successStoriesSwiper = new Swiper('.success-stories-swiper', {
	slidesPerView: 1.2,
	spaceBetween: 15,
	loop: false,
	centeredSlides: true,
	breakpoints: {
		992: {
			slidesPerView: 1,
			centeredSlides: false,
			spaceBetween: 20,
		},
	},
	autoplay: {
		delay: 4000,
		disableOnInteraction: true,
	},
	on: {
		slideChange: (swiper) => {
			const currentSlideIndex = swiper.realIndex;
			storiesNav
				.querySelector('li.active-slide')
				.classList.remove('active-slide');
			const activeSlideNav = storiesNav.querySelector(
				`img[data-slide="${currentSlideIndex}"]`,
			);
			activeSlideNav.parentElement.classList.add('active-slide');
			calculateNavWidth(activeSlideNav, storiesNav, 'stories');
		},
	},
});

// Set the slider nav active class and width
calculateNavWidth(StoriesNavLinkActive, storiesNav, 'stories');
storiesNavLinks.forEach((storiesNavLink) => {
	storiesNavLink.addEventListener('click', () => {
		successStoriesSwiper.slideTo(storiesNavLink.dataset.slide);
		storiesNav
			.querySelector('li.active-slide')
			.classList.remove('active-slide');
		storiesNavLink.parentElement.classList.add('active-slide');
		calculateNavWidth(storiesNavLink, storiesNav, 'stories');
	});
});
// Recalculate the slider nav on window resize
window.addEventListener('resize', () => {
	const storiesNavLinkActiveSlide =
		storiesNav.querySelector('li.active-slide');
	calculateNavWidth(storiesNavLinkActiveSlide, storiesNav, 'stories');
});

// Auto slide Success stories swiper
autoSlide(storiesNavLinks, 10000);
